<template>
	<div class="createParks">
		<div class="main-filter">
			<el-form inline :model="queryData" class="demo-form-inline search-form">
				<el-form-item label="模块名称：">
					<el-autocomplete v-model.trim="queryData.title" :fetch-suggestions="querySearchAsync" clearable placeholder="请输入内容"></el-autocomplete>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSearch" icon="el-icon-search">搜索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="main-content">
			<div class="main-content-title">
				<p>共有 {{ total }} 条结果</p>
				<el-button type="primary" @click="modifyParkInfo($event, false)" icon="el-icon-plus">新增</el-button>
			</div>
			<Table
				:loading="loading"
				:table-data="tableData"
				:total="total"
				:table-head="tableHead"
				:current-page.sync="queryData.current"
				:page-size.sync="queryData.size"
				:is-show-selection="false"
				:operation-column-width="164"
				:showOperation="true"
				@change-page="getList"
				:tableHeight="tableHeight"
			>
				<!-- 插槽操作栏固定属性operation -->
				<template #enabled="{ scope }">
					<el-switch inactive-text="否" active-text="是" v-model="scope.row.enabled" @change="changeOpen(scope.row)"> </el-switch>
				</template>
				<template #operation="{ scope }">
					<el-button @click="modifyParkInfo($event, true, scope.row)" size="mini" type="primary"> 查看 </el-button>
					<el-button
						@click="modifyParkInfo($event, false, scope.row)"
						size="mini"
						:type="scope.row.enabled ? 'info' : 'warning'"
						:disabled="scope.row.enabled"
					>
						修改
					</el-button>
				</template>
			</Table>
		</div>
		<createView @getList="getList" ref="createView" :isShowDetail="isShowDetail" />
	</div>
</template>

<script>
import { Session, Local } from '@/utils/storage.js';
export default {
	name: 'countyMedicalManager',
	components: {
		Table: () => import('@/components/Table/table'),
		createView: () => import('./newDialog.vue'),
	},
	data() {
		return {
			loading: false,
			queryData: {
				current: 1,
				size: 10,
			},
			total: 0,
			tableData: [],
			tableHead: [
				{
					label: '工作模块名称',
					prop: 'title',
					formatter: (row) => {
						return row.title || '-';
					},
				},
				{
					label: '模块详情',
					prop: 'description',
					formatter: (row) => {
						return row.description || '-';
					},
				},
				{
					label: '创建人',
					prop: 'userName',
					formatter: (row) => {
						return Session.get('userInfo').displayName|| '-';
					},
				},
				{
					label: '是否启用',
					prop: 'enabled',
					width: 116,
				},
			],
			isShowDetail: false,
			moduleId: '',
			link:"",
		};
	},
	props: [],
	computed: {
		tableHeight() {
			return window.innerHeight - 300;
		},
	},
	mounted() {
		this.getDicId();
		// this.getList();
	},
	methods: {
		// 获取巡检模块字典
		getDicId() {
			this.$http
				.get(this.api['Dictionaries#index'].href, { params: { subjectTypeCode: 'RegionManagementGroup',subjectId:'~',code: 'TOUR_PLAN_MODULE' } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.moduleId = res.data.collection[0].id;
						this.getList();
					}
				})
				.catch((e) => {});
		},
		// 获取省数据
		getEconomizeList() {
			this.$http
				.get(this.api['ChinaRegions#index'].href)
				.then((res) => {
					if (res.data && res.data.success) {
						this.economizeList = res.data.collection || [];
						this.economizeList.unshift({
							title: '全部',
							code: '',
						});
					}
				})
				.catch((e) => {});
		},
		// 切换省份后拿到市区数据
		changeEcon(val) {
			this.formInline.cityCode = '';
			if (!val || val === '') return (this.marketList = []);
			this.$http
				.get(this.api['ChinaRegions#index'].href, { params: { code: val } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.marketList = res.data.collection || [];
						this.marketList.unshift({
							title: '全部',
							code: '',
						});
					}
				})
				.catch((e) => {});
		},
		//切换市区  获取县级数据
		changeMarket(val) {
			console.log(val, 'val');
		},
		// 搜索建议
		querySearchAsync(queryString, cb) {
			queryString ? this.getList('filter') : '';
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				cb(this.options);
			}, 1000 * Math.random());
		},
		onSearch(data) {
			this.queryData.current = 1;
			this.getList('');
		},
		changeOpen(row) {
		this.$http
				.patch(row._links.patch.href, { enabled: row.enabled })
				.then((res) => {
					if (res.data && res.data.success) {
						this.$message.success('操作成功');
						this.getList();
					}
				})
				.catch((e) => {});
		},
		//获取列表
		getList(type = '') {
			type == 'filter' ? '' : (this.loading = true);
			this.queryData.dictionaryId = this.moduleId;
			this.$http
				.get(this.api['DictionaryItems#index'].href, { params: this.queryData })
				.then((res) => {
					if (res.data && res.data.success) {
						if (type == 'filter') {
							this.filterLoading = false;
							this.options = res.data.collection.map((item) => {
								return {
									...item,
									value: item.title,
								};
							});
						} else {
							this.tableData = res.data.collection || [];
							this.total = res.data.pagination.totalItems || 0;
							this.loading = false;
							this.link=res.data._links.create.href||''
						}
					}
				})
				.catch((e) => {
					this.loading = false;
				});
		},
		modifyParkInfo(event, isShowDetail, row) {
				let data = {
				row: row || null,
				link: row ? row._links.update.href : this.link,
			};
			this.$refs.createView.init(data);
			this.$refs.createView.dictionaryId = this.moduleId;
			this.isShowDetail = isShowDetail;
		},
	},
};
</script>

<style lang="scss" scoped>
/deep/ .search-form {
	.el-select {
		width: 120px;
		.el-input {
			width: 120px;
			.el-input__inner {
				width: 120px;
			}
		}
	}
	.el-input {
		width: 160px;
	}
}
.main-content {
	&-title {
		line-height: 48px;
		display: flex;
		justify-content: space-between;
		.el-button {
			line-height: 32px;
			padding: 0 16px;
			height: 32px;
			margin-top: 8px;
		}
	}
}
.seeMore {
	color: #1db9b1;
}
</style>
